import { useEffect, useState } from 'react';
import { Form, Modal, Select, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInput } from '@components';
import { HttpUtil } from '@utils';
import dayjs from 'dayjs';
import PAY_SLIP_SIGN from '@assets/img/pay_slip_sign.png';
import '@css/pay_slip.scss';

type TData = {
  teacherName: string,
  className: string,
  month: string,
  headcount: number,
  totalPay: number,
  incomeTax: number,
  residentTax: number,
  manuscript: number,
  wci: number,
  totalTax: number,
  netAmount: number,
  bookCost: number
}

const PaySlip = () => {
  const [data, setData] = useState<TData[]>([]);
  const [teacherId, setTeacherId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [targetMonths, setTargetMonths] = useState([]);
  const [targetMonth, setTargetMonth] = useState('');

  const [form] = useForm();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (!data.length) {
      setShowModal(true);
    }
  }, [data]);

  const getArgs = (tName: string, tId: string, tMonth: string) => ({
    url: '/pay_slip',
    method: 'GET',
    params: {
      name: tName,
      teacherId: tId,
      targetMonth: tMonth
    }
  });

  const onFinish = async (values: any) => {
    setShowModal(false);
    setTeacherId(values.teacherId);
    setTimeout(() => { form.resetFields() }, 150);

    const now = dayjs();
    const year = now.year();
    const day = now.date();

    const month = now.month() + (day > 24 ? 1 : 0);
    const targetMonth = `${year}-${month > 9 ? month : '0' + month}`;

    const args = getArgs(values.name, values.teacherId, targetMonth);
    const res = await HttpUtil.requestApi(args);

    const { data, msg } = res;

    if (msg) {
      window.alert(msg);
      return;
    }
    if (!data.paySlip.length) {
      window.alert('해당 강사의 정보가 존재하지 않습니다. 강사명 또는 생년월일을 확인해주세요.');
      setShowModal(true);
      return;
    }
    setData(data.paySlip);
    setTargetMonths(data.months.map((v: string) => ({ label: v, value: v })));
    setTargetMonth(targetMonth || data.months[0]);
  }

  const onOk = async () => {
    try {
      await form.validateFields(['name', 'teacherId']);
      onFinish(form.getFieldsValue());
    } catch (e) {
      console.error(e);
    }
  }

  const getValueWithComma = (v: number) => {
    return !v ? v : v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  const onMonthChange = async (value: string) => {
    setTargetMonth(value);
    const res = await HttpUtil.requestApi(getArgs(data[0].teacherName, teacherId, value));
    if (!res.data.paySlip.length) {
      api.error({
        message: '알림',
        description: '해당 월의 명세서가 없습니다.',
        placement: 'top',
        duration: 2,
        className: 'oreo'
      });
    } else {
      setData(res.data.paySlip);
    }
  }

  return (
    <>
      {contextHolder}
      {!data.length ? <></> : <div className='pay-slip'>
        <div>
          <h1>급 여 지 급 명 세 서</h1>
          <section className='clearfix'>
            <div className='month'>
              <Select options={targetMonths} onChange={onMonthChange} value={targetMonth} />
            </div>
            <ul className='info'>
              <li>
                <span>소&nbsp; &nbsp;속</span>
                <span>주식회사 밝은생각</span>
              </li>
              <li>
                <span>강좌명</span>
                <span>
                  {data.length > 1 ? data[0].className + ` 외 ${data.length - 1}건` : (data[0].className ?? '코디네이터')}
                </span>
              </li>
              <li>
                <span>성&nbsp; &nbsp;명</span>
                <span>{data[0].teacherName}</span>
              </li>
            </ul>
          </section>
          <section className='net-amount'>
            <h3>1. 실지급액</h3>
            <ul>
              <li>
                <div>지급합계</div>
                <div>{getValueWithComma(data[0].totalPay)}</div>
              </li>
              <li>
                <div>공제합계</div>
                <div>{getValueWithComma(data[0].totalTax)}</div>
              </li>
              <li>
                <div>실수령액</div>
                <div>{getValueWithComma(data[0].netAmount)}</div>
              </li>
            </ul>
          </section>
          <section className='detail'>
            <h3>2. 지급내역</h3>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>지급항목</th>
                  <th colSpan={2}>공제항목</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>수강인원</td>
                  <td>지급 수강료</td>
                  <td>소득세</td>
                  <td className='t-r'>{getValueWithComma(data[0].incomeTax)}</td>
                </tr>
                <tr>
                  <td className='t-r headcount'>{getValueWithComma(data[0].headcount)}</td>
                  <td className='t-r'>{getValueWithComma(data[0].totalPay - data[0].bookCost)}</td>
                  <td>주민세</td>
                  <td className='t-r'>{getValueWithComma(data[0].residentTax)}</td>
                </tr>
                <tr>
                  <td>교재(재료)비</td>
                  <td className='t-r'>{getValueWithComma(data[0].bookCost)}</td>
                  <td>특고료</td>
                  <td className='t-r'>{getValueWithComma(data[0].manuscript)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>산재보험료</td>
                  <td className='t-r'>{getValueWithComma(data[0].wci)}</td>
                </tr>
                <tr><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td></tr>
              </tbody>
            </table>
          </section>
          <div className='add-etc'>※ 25일 이전에는 전월의 명세서가 표시됩니다.</div>
          <p>
            귀하의 노고에 감사드립니다.
            <img src={PAY_SLIP_SIGN} alt='' />
          </p>
        </div>
      </div>}
      <Modal className='oreo pay-slip' onOk={onOk} open={showModal}>
        <h1>급여명세서 조회</h1>
        <Form form={form} className='oreo'>
          <FormInput label='이름' name='name' placeholder='강사명' />
          <FormInput label='생년월일' name='teacherId' placeholder='850101' />
        </Form>
      </Modal>
    </>
  );
}

export default PaySlip;